import './App.css';
import { useEffect } from 'react';

function App() {
  useEffect(() => {
    const webgazer = window.webgazer

    console.log(webgazer)
    
    webgazer.setGazeListener((data, timestamp) => {
      console.log(data, timestamp)
    }).begin()
  }, []);

  return (
    <div>
      Hello World
    </div>
  );
}

export default App;